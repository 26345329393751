body{
  font-family: 'Lato', sans-serif;

}
html, body {
  scroll-behavior: smooth;
}


nav{
  z-index: 10001 !important;
}

.gradientBg{
  background: linear-gradient(99deg, #EE9AE5 0%, #5961F9 100%);
}
.gradientBg2{
  background: linear-gradient(170deg,#000 0%,#2B2B2BE6 100%);
}
.btnPrimary{
  @apply py-3 px-8 bg-secondary font-semibold text-white rounded hover:bg-primary transition-all duration-300;
}